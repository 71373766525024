export const environment = {
  production: false,
  localBackend: false,
  locales: ['en-US', 'jp-JP'],
  defaultLocale: 'en-US',
  firebaseConfig: {
    apiKey: 'AIzaSyCx0PD1FJCQUNgKzqGq27Ih9HVOvpqPkGc',
    authDomain: 'us-buki-staging.firebaseapp.com',
    projectId: 'us-buki-staging',
    storageBucket: 'us-buki-staging.appspot.com',
    messagingSenderId: '655147678874',
    appId: '1:655147678874:web:df5babc2cf7c24ddd23323',
    databaseURL: 'https://us-buki-staging-default-rtdb.firebaseio.com',
  },
};
