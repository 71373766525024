import {
  BOOK_ALIAS_STARRY_DREAM,
  BOOK_ALIAS_STORY_OF_GRANDPA_GRANDMA,
  BOOK_ALIAS_WELCOME_TO_THE_WOLD,
  BOOK_ALIAS_WHERE_IS_BIRTHDAY_CAKE,
  BOOK_ALIAS_WHOS_BIRTHDAY_TOMORROW,
  BOOK_ID_STARRY_DREAM,
  BOOK_ID_STORY_OF_GRANDPA_GRANDMA,
  BOOK_ID_WELCOME_TO_THE_WOLD,
  BOOK_ID_WHERE_IS_BIRTHDAY_CAKE,
  BOOK_ID_WHOS_BIRTHDAY_TOMORROW,
  INPUT_VALUE_BEARD_STYLE_SOGG_B1,
  INPUT_VALUE_BEARD_STYLE_SOGG_B2,
  INPUT_VALUE_BEARD_STYLE_SOGG_B3,
  INPUT_VALUE_BEARD_STYLE_SOGG_NONE,
  INPUT_VALUE_CHARACTER_BOY1,
  INPUT_VALUE_CHARACTER_BOY2,
  INPUT_VALUE_CHARACTER_BOY3,
  INPUT_VALUE_CHARACTER_BOY4,
  INPUT_VALUE_CHARACTER_BOY5,
  INPUT_VALUE_CHARACTER_BOY6,
  INPUT_VALUE_CHARACTER_GIRL1,
  INPUT_VALUE_CHARACTER_GIRL2,
  INPUT_VALUE_CHARACTER_GIRL3,
  INPUT_VALUE_CHARACTER_GIRL4,
  INPUT_VALUE_CHARACTER_GIRL5,
  INPUT_VALUE_CHARACTER_GIRL6,
  INPUT_VALUE_ENGLISH,
  INPUT_VALUE_GENDER_GRANDMA,
  INPUT_VALUE_GENDER_GRANDPA,
  INPUT_VALUE_HAIR_COLOR_SOGG_C1,
  INPUT_VALUE_HAIR_COLOR_SOGG_C2,
  INPUT_VALUE_HAIR_COLOR_SOGG_C3,
  INPUT_VALUE_HAIR_COLOR_SOGG_C4,
  INPUT_VALUE_HAIR_COLOR_SOGG_C5,
  INPUT_VALUE_HAIR_STYLE_GRANDMA_H01,
  INPUT_VALUE_HAIR_STYLE_GRANDMA_H02,
  INPUT_VALUE_HAIR_STYLE_GRANDMA_H03,
  INPUT_VALUE_HAIR_STYLE_GRANDMA_H04,
  INPUT_VALUE_HAIR_STYLE_GRANDMA_H05,
  INPUT_VALUE_HAIR_STYLE_GRANDMA_H06,
  INPUT_VALUE_HAIR_STYLE_GRANDPA_H01,
  INPUT_VALUE_HAIR_STYLE_GRANDPA_H02,
  INPUT_VALUE_HAIR_STYLE_GRANDPA_H03,
  INPUT_VALUE_HAIR_STYLE_GRANDPA_H04,
  INPUT_VALUE_HAIR_STYLE_GRANDPA_H05,
  INPUT_VALUE_HAIR_STYLE_GRANDPA_H06,
  INPUT_VALUE_HAIR_STYLE_H01,
  INPUT_VALUE_HAIR_STYLE_H02,
  INPUT_VALUE_HAIR_STYLE_H03,
  INPUT_VALUE_HAIR_STYLE_H04,
  INPUT_VALUE_HAIR_STYLE_H05,
  INPUT_VALUE_HAIR_STYLE_H06,
  INPUT_VALUE_HAIR_STYLE_H07,
  INPUT_VALUE_HAIR_STYLE_H08,
  INPUT_VALUE_HAIR_STYLE_H09,
  INPUT_VALUE_HAIR_STYLE_H10,
  INPUT_VALUE_JAPANESE,
  INPUT_VALUE_SKIN_COLOR_SOGG_C1,
  INPUT_VALUE_SKIN_COLOR_SOGG_C2,
  INPUT_VALUE_SKIN_COLOR_SOGG_C3,
  INPUT_VALUE_SKIN_COLOR_SOGG_C4,
  INPUT_VALUE_TIME_OF_THE_YEAR_AUTUMN,
  INPUT_VALUE_TIME_OF_THE_YEAR_SPRING,
  INPUT_VALUE_TIME_OF_THE_YEAR_SUMMER,
  INPUT_VALUE_TIME_OF_THE_YEAR_WINTER,
  PHOTO_STARRY_DREAM,
  PHOTO_STORY_OF_GRANDPA_GRANDMA_MAIN,
  PHOTO_STORY_OF_GRANDPA_GRANDMA_SUB_1,
  PHOTO_STORY_OF_GRANDPA_GRANDMA_SUB_2,
  PHOTO_STORY_OF_GRANDPA_GRANDMA_SUB_3,
  PHOTO_WELCOME_TO_THE_WOLD,
  PHOTO_WHERE_IS_BIRTHDAY_CAKE,
  PHOTO_WHOS_BIRTHDAY_TOMORROW,
} from './constants';

import type {
  TBeardStyleSOGG,
  TBookAlias,
  TCharacterSD,
  TGenderGrandpaGrandma,
  THairWIBC,
  THoroscopes,
  TLanguage,
  TPlanet,
  TSHairColorSOGG,
  TSkinColorSOGG,
} from './interfaces';

export const ALIAS_TO_ID_MAP = {
  [BOOK_ALIAS_STARRY_DREAM]: BOOK_ID_STARRY_DREAM,
  [BOOK_ALIAS_WELCOME_TO_THE_WOLD]: BOOK_ID_WELCOME_TO_THE_WOLD,
  [BOOK_ALIAS_WHOS_BIRTHDAY_TOMORROW]: BOOK_ID_WHOS_BIRTHDAY_TOMORROW,
  [BOOK_ALIAS_WHERE_IS_BIRTHDAY_CAKE]: BOOK_ID_WHERE_IS_BIRTHDAY_CAKE,
  [BOOK_ALIAS_STORY_OF_GRANDPA_GRANDMA]: BOOK_ID_STORY_OF_GRANDPA_GRANDMA,
} as const;

export const ALIAS_TO_YOTPO_EXTERNAL_ID_MAP = {
  [BOOK_ALIAS_STARRY_DREAM]: 'ytp_bk_us_1',
  [BOOK_ALIAS_WHOS_BIRTHDAY_TOMORROW]: 'ytp_bk_us_2',
  [BOOK_ALIAS_WELCOME_TO_THE_WOLD]: 'ytp_bk_us_3',
  [BOOK_ALIAS_WHERE_IS_BIRTHDAY_CAKE]: 'ytp_bk_us_5',
  [BOOK_ALIAS_STORY_OF_GRANDPA_GRANDMA]: 'ytp_bk_us_7',
} as const;

export const ALIAS_TO_NAME_MAP = {
  [BOOK_ALIAS_STARRY_DREAM]: 'Adventure in the Stars',
  [BOOK_ALIAS_WELCOME_TO_THE_WOLD]: 'Welcome to the World',
  [BOOK_ALIAS_WHOS_BIRTHDAY_TOMORROW]: 'Tomorrow is Your Birthday!',
  [BOOK_ALIAS_WHERE_IS_BIRTHDAY_CAKE]: 'Where is the birthday cake?',
  [BOOK_ALIAS_STORY_OF_GRANDPA_GRANDMA]:
    'わたしたちの、おじいちゃん/おばあちゃん',
} as const;

export const GENDER_TO_HAIR_STYLE_WIBC_MAP = {
  boy: [
    INPUT_VALUE_HAIR_STYLE_H02,
    INPUT_VALUE_HAIR_STYLE_H03,
    INPUT_VALUE_HAIR_STYLE_H04,
    INPUT_VALUE_HAIR_STYLE_H05,
    INPUT_VALUE_HAIR_STYLE_H01,
  ],
  girl: [
    INPUT_VALUE_HAIR_STYLE_H07,
    INPUT_VALUE_HAIR_STYLE_H08,
    INPUT_VALUE_HAIR_STYLE_H09,
    INPUT_VALUE_HAIR_STYLE_H10,
    INPUT_VALUE_HAIR_STYLE_H06,
  ],
} as const;

export const GENDER_TO_CHARACTER_SD_MAP = {
  boy: [
    INPUT_VALUE_CHARACTER_BOY1,
    INPUT_VALUE_CHARACTER_BOY2,
    INPUT_VALUE_CHARACTER_BOY3,
    INPUT_VALUE_CHARACTER_BOY4,
    INPUT_VALUE_CHARACTER_BOY5,
    INPUT_VALUE_CHARACTER_BOY6,
  ],
  girl: [
    INPUT_VALUE_CHARACTER_GIRL1,
    INPUT_VALUE_CHARACTER_GIRL2,
    INPUT_VALUE_CHARACTER_GIRL3,
    INPUT_VALUE_CHARACTER_GIRL4,
    INPUT_VALUE_CHARACTER_GIRL5,
    INPUT_VALUE_CHARACTER_GIRL6,
  ],
} as const;

export const HAIR_STYLE_WIBC_TO_NAME_MAP: {
  [key in THairWIBC]: string;
} = {
  [INPUT_VALUE_HAIR_STYLE_H01]: 'シェイブド',
  [INPUT_VALUE_HAIR_STYLE_H02]: 'ショート',
  [INPUT_VALUE_HAIR_STYLE_H03]: 'つんつん',
  [INPUT_VALUE_HAIR_STYLE_H04]: 'ロング',
  [INPUT_VALUE_HAIR_STYLE_H05]: 'カール',
  [INPUT_VALUE_HAIR_STYLE_H06]: 'スカーフ',
  [INPUT_VALUE_HAIR_STYLE_H07]: 'ショート',
  [INPUT_VALUE_HAIR_STYLE_H08]: 'ミディアム',
  [INPUT_VALUE_HAIR_STYLE_H09]: 'ロング',
  [INPUT_VALUE_HAIR_STYLE_H10]: 'ウェーブ',
};

export const CHARACTER_SD_TO_NAME_MAP: {
  [key in TCharacterSD]: string;
} = {
  [INPUT_VALUE_CHARACTER_BOY1]: 'todo BOY1',
  [INPUT_VALUE_CHARACTER_BOY2]: 'todo BOY2',
  [INPUT_VALUE_CHARACTER_BOY3]: 'todo BOY3',
  [INPUT_VALUE_CHARACTER_BOY4]: 'todo BOY4',
  [INPUT_VALUE_CHARACTER_BOY5]: 'todo BOY5',
  [INPUT_VALUE_CHARACTER_BOY6]: 'todo BOY6',
  [INPUT_VALUE_CHARACTER_GIRL1]: 'todo GIRL1',
  [INPUT_VALUE_CHARACTER_GIRL2]: 'todo GIRL2',
  [INPUT_VALUE_CHARACTER_GIRL3]: 'todo GIRL3',
  [INPUT_VALUE_CHARACTER_GIRL4]: 'todo GIRL4',
  [INPUT_VALUE_CHARACTER_GIRL5]: 'todo GIRL5',
  [INPUT_VALUE_CHARACTER_GIRL6]: 'todo GIRL6',
};

export const LANGUAGE_TO_NAME_MAP: {
  [key in TLanguage]: string;
} = {
  [INPUT_VALUE_ENGLISH]: 'English',
  [INPUT_VALUE_JAPANESE]: 'Japanese',
};

export const PLANET_TO_NAME_MAP: {
  [key in TPlanet]: string;
} = {
  planet0: '勇気の星',
  planet1: '友情の星',
  planet2: '学びの星',
  planet3: '創造の星',
  planet4: '情熱の星',
  planet5: '思いやりの星',
};

// Hiragana
export const PLANET_TO_NAME_MAP_BOOK: {
  [key in TPlanet]: string;
} = {
  planet0: 'ゆうきのほし',
  planet1: 'ゆうじょうのほし',
  planet2: 'まなびのほし',
  planet3: 'そうぞうのほし',
  planet4: 'じょうねつのほし',
  planet5: 'おもいやりのほし',
};

export const EN_PLANET_TO_NAME_MAP: {
  [key in TPlanet]: string;
} = {
  planet0: 'the Planet of Bravery',
  planet1: 'the Planet of Friendship',
  planet2: 'the Planet of Learning',
  planet3: 'the Planet of Creativity',
  planet4: 'the Planet of Passion',
  planet5: 'the Planet of Compassion',
};

export const EN_SUMMARY_PLANET_TO_NAME_MAP: {
  [key in TPlanet]: string;
} = {
  planet0: 'Courage',
  planet1: 'Friendship',
  planet2: 'Learning',
  planet3: 'Creativity',
  planet4: 'Passion',
  planet5: 'Compassion',
};

export const JP_SUMMARY_PLANET_TO_NAME_MAP: {
  [key in TPlanet]: string;
} = {
  planet0: '勇気',
  planet1: '友情',
  planet2: '学び',
  planet3: '創造',
  planet4: '情熱',
  planet5: '思いやり',
};

export const ZODIAC_TO_NAME_MAP: {
  [key in THoroscopes]: string;
} = {
  aries: 'おひつじざ',
  taurus: 'おうしざ',
  gemini: 'ふたござ',
  cancer: 'かにざ',
  leo: 'ししざ',
  virgo: 'おとめざ',
  libra: 'てんびんざ',
  scorpio: 'さそりざ',
  sagittarius: 'いてざ',
  capricorn: 'やぎざ',
  aquarius: 'みずがめざ',
  pisces: 'うおざ',
};

export const MONTH_INDEX_TO_TIME_OF_THE_YEAR_MAP = {
  11: INPUT_VALUE_TIME_OF_THE_YEAR_WINTER,
  0: INPUT_VALUE_TIME_OF_THE_YEAR_WINTER,
  1: INPUT_VALUE_TIME_OF_THE_YEAR_WINTER,
  2: INPUT_VALUE_TIME_OF_THE_YEAR_SPRING,
  3: INPUT_VALUE_TIME_OF_THE_YEAR_SPRING,
  4: INPUT_VALUE_TIME_OF_THE_YEAR_SPRING,
  5: INPUT_VALUE_TIME_OF_THE_YEAR_SUMMER,
  6: INPUT_VALUE_TIME_OF_THE_YEAR_SUMMER,
  7: INPUT_VALUE_TIME_OF_THE_YEAR_SUMMER,
  8: INPUT_VALUE_TIME_OF_THE_YEAR_AUTUMN,
  9: INPUT_VALUE_TIME_OF_THE_YEAR_AUTUMN,
  10: INPUT_VALUE_TIME_OF_THE_YEAR_AUTUMN,
} as const;

export const PHOTO_FILE_NAME: { [key in TBookAlias]: string | string[] } = {
  [BOOK_ALIAS_STARRY_DREAM]: PHOTO_STARRY_DREAM,
  [BOOK_ALIAS_WELCOME_TO_THE_WOLD]: PHOTO_WELCOME_TO_THE_WOLD,
  [BOOK_ALIAS_WHOS_BIRTHDAY_TOMORROW]: PHOTO_WHOS_BIRTHDAY_TOMORROW,
  [BOOK_ALIAS_WHERE_IS_BIRTHDAY_CAKE]: PHOTO_WHERE_IS_BIRTHDAY_CAKE,
  [BOOK_ALIAS_STORY_OF_GRANDPA_GRANDMA]: [
    PHOTO_STORY_OF_GRANDPA_GRANDMA_MAIN,
    PHOTO_STORY_OF_GRANDPA_GRANDMA_SUB_1,
    PHOTO_STORY_OF_GRANDPA_GRANDMA_SUB_2,
    PHOTO_STORY_OF_GRANDPA_GRANDMA_SUB_3,
  ],
};

export const GENDER_TO_HAIR_STYLE_SOGG_MAP = {
  [INPUT_VALUE_GENDER_GRANDPA]: [
    INPUT_VALUE_HAIR_STYLE_GRANDPA_H01,
    INPUT_VALUE_HAIR_STYLE_GRANDPA_H02,
    INPUT_VALUE_HAIR_STYLE_GRANDPA_H03,
    INPUT_VALUE_HAIR_STYLE_GRANDPA_H04,
    INPUT_VALUE_HAIR_STYLE_GRANDPA_H05,
    INPUT_VALUE_HAIR_STYLE_GRANDPA_H06,
  ],
  [INPUT_VALUE_GENDER_GRANDMA]: [
    INPUT_VALUE_HAIR_STYLE_GRANDMA_H01,
    INPUT_VALUE_HAIR_STYLE_GRANDMA_H02,
    INPUT_VALUE_HAIR_STYLE_GRANDMA_H03,
    INPUT_VALUE_HAIR_STYLE_GRANDMA_H04,
    INPUT_VALUE_HAIR_STYLE_GRANDMA_H05,
    INPUT_VALUE_HAIR_STYLE_GRANDMA_H06,
  ],
} as const;

const MAKE_SKIN_COLOR_SOGG_TO_LOCALE_KEY_MAP = <
  T extends {
    readonly [key in TSkinColorSOGG]: string;
  },
>(
  o: T,
) => o;
export const SKIN_COLOR_SOGG_TO_LOCALE_KEY_MAP =
  MAKE_SKIN_COLOR_SOGG_TO_LOCALE_KEY_MAP({
    [INPUT_VALUE_SKIN_COLOR_SOGG_C1]: 'skin-color.white',
    [INPUT_VALUE_SKIN_COLOR_SOGG_C2]: 'skin-color.nutmeg',
    [INPUT_VALUE_SKIN_COLOR_SOGG_C3]: 'skin-color.brown',
    [INPUT_VALUE_SKIN_COLOR_SOGG_C4]: 'skin-color.black',
  } as const);

const MAKE_HAIR_COLOR_SOGG_TO_LOCALE_KEY_MAP = <
  T extends {
    readonly [key in TSHairColorSOGG]: string;
  },
>(
  o: T,
) => o;
export const HAIR_COLOR_SOGG_TO_LOCALE_KEY_MAP =
  MAKE_HAIR_COLOR_SOGG_TO_LOCALE_KEY_MAP({
    [INPUT_VALUE_HAIR_COLOR_SOGG_C1]: 'hair-color.blonde',
    [INPUT_VALUE_HAIR_COLOR_SOGG_C2]: 'hair-color.red',
    [INPUT_VALUE_HAIR_COLOR_SOGG_C3]: 'hair-color.brown',
    [INPUT_VALUE_HAIR_COLOR_SOGG_C4]: 'hair-color.black',
    [INPUT_VALUE_HAIR_COLOR_SOGG_C5]: 'hair-color.grey',
  } as const);

const MAKE_GENDER_TO_HAIR_STYLE_SOGG_TO_LOCALE_KEY_MAP = <
  T extends {
    readonly [gender in TGenderGrandpaGrandma]: {
      [key in typeof GENDER_TO_HAIR_STYLE_SOGG_MAP[gender][number]]: string;
    };
  },
>(
  o: T,
) => o;
export const GENDER_TO_HAIR_STYLE_SOGG_TO_LOCALE_KEY_MAP =
  MAKE_GENDER_TO_HAIR_STYLE_SOGG_TO_LOCALE_KEY_MAP({
    [INPUT_VALUE_GENDER_GRANDPA]: {
      [INPUT_VALUE_HAIR_STYLE_GRANDPA_H01]: 'grandpa.hair-style.bald',
      [INPUT_VALUE_HAIR_STYLE_GRANDPA_H02]: 'grandpa.hair-style.new_bald',
      [INPUT_VALUE_HAIR_STYLE_GRANDPA_H03]: 'grandpa.hair-style.short',
      [INPUT_VALUE_HAIR_STYLE_GRANDPA_H04]: 'grandpa.hair-style.side_block',
      [INPUT_VALUE_HAIR_STYLE_GRANDPA_H05]: 'grandpa.hair-style.long',
      [INPUT_VALUE_HAIR_STYLE_GRANDPA_H06]: 'grandpa.hair-style.curl',
    },
    [INPUT_VALUE_GENDER_GRANDMA]: {
      [INPUT_VALUE_HAIR_STYLE_GRANDMA_H01]: 'grandma.hair-style.pixie',
      [INPUT_VALUE_HAIR_STYLE_GRANDMA_H02]: 'grandma.hair-style.short',
      [INPUT_VALUE_HAIR_STYLE_GRANDMA_H03]: 'grandma.hair-style.medium',
      [INPUT_VALUE_HAIR_STYLE_GRANDMA_H04]: 'grandma.hair-style.long',
      [INPUT_VALUE_HAIR_STYLE_GRANDMA_H05]: 'grandma.hair-style.wave',
      [INPUT_VALUE_HAIR_STYLE_GRANDMA_H06]: 'grandma.hair-style.curl',
    },
  } as const);

const MAKE_BEARD_STYLE_SOGG_TO_LOCALE_KEY_MAP = <
  T extends {
    readonly [key in TBeardStyleSOGG]: string;
  },
>(
  o: T,
) => o;
export const BEARD_STYLE_SOGG_TO_LOCALE_KEY_MAP =
  MAKE_BEARD_STYLE_SOGG_TO_LOCALE_KEY_MAP({
    [INPUT_VALUE_BEARD_STYLE_SOGG_B1]: 'beard-style.mustache',
    [INPUT_VALUE_BEARD_STYLE_SOGG_B2]: 'beard-style.full',
    [INPUT_VALUE_BEARD_STYLE_SOGG_B3]: 'beard-style.santa',
    [INPUT_VALUE_BEARD_STYLE_SOGG_NONE]: 'beard-style.none',
  } as const);
