import type { AbstractControl, FormGroup } from '@angular/forms';
import memoizee from 'memoizee';
import type {
  TBookAlias,
  TBookCover,
  TBookWrapping,
  TGender,
  TGenderGrandpaGrandma,
  TPlanet,
} from '@shared/book/interfaces';
import {
  BOOK_ALIAS_STARRY_DREAM,
  BOOK_ALIAS_STORY_OF_GRANDPA_GRANDMA,
  BOOK_ALIAS_WELCOME_TO_THE_WOLD,
  BOOK_ALIAS_WHERE_IS_BIRTHDAY_CAKE,
  BOOK_ALIAS_WHOS_BIRTHDAY_TOMORROW,
} from '@shared/book/constants';
import jpJP from '@shared/translations/jp-JP.json';
import enUS from '@shared/translations/en-US.json';
import type StripeBE from 'stripe';
import type {
  BookInOrder,
  IPaymentInformation,
  TCouponData,
} from '@shared/interfaces';
import type {
  BookData,
  BookStoryOfGrandpaGrandmaData,
  BookWelcomeToTheWoldData,
  BookWhereIsBirthdayCakeData,
  BookWhosBirthdayTomorrowData,
} from '@shared/models';
import {
  BEARD_STYLE_SOGG_TO_LOCALE_KEY_MAP,
  EN_SUMMARY_PLANET_TO_NAME_MAP,
  GENDER_TO_HAIR_STYLE_SOGG_TO_LOCALE_KEY_MAP,
  HAIR_COLOR_SOGG_TO_LOCALE_KEY_MAP,
  HAIR_STYLE_WIBC_TO_NAME_MAP,
  SKIN_COLOR_SOGG_TO_LOCALE_KEY_MAP,
} from '@shared/book/maps';
import type { TShippingMethod } from '@shared/shipping/interfaces';
import formatInTimeZone from 'date-fns-tz/formatInTimeZone';
import format from 'date-fns/format';
import type { IDiscountCampaign } from '@shared/discount/interfaces';
import {
  calculateDiscount,
  getBooksDiscount,
  getShippingDiscount,
} from '@shared/discount/utils';
import capitalize from 'lodash/capitalize';
import { getError } from '@shared/utils';
import { environment } from 'environments/environment';
import type { IShipping } from './data/shipping/types';
import type { IBookPrice, TBookPrices } from './data/books/prices/types';
import type { ICalculatorComponentItem } from './shared/calculator/calculator.component';
import { getBookByAlias } from './data/books/utils';
import { Logger } from './services/logger.service';
import { wrappingDefault, wrappings } from './static';
import type { Cover, Product, Specs, Wrapping } from './interfaces';

const FEToBEWrappingNameLog = new Logger('FEToBEWrappingName');
export const FEToBEWrappingName = (
  name: Wrapping['name'],
): BookInOrder['wrapping'] => {
  const log = FEToBEWrappingNameLog;

  switch (name) {
    case 'No':
      return 'standart';
    case 'Gift Wrapping':
      return 'gift';
    default:
      log.error(`didn't recognize ${name}`);
      return 'standart';
  }
};

const BEToFEWrappingNameLog = new Logger('BEToFEWrappingName');
export const BEToFEWrappingName = (
  name: BookInOrder['wrapping'],
): Wrapping['name'] => {
  const log = BEToFEWrappingNameLog;

  switch (name) {
    case 'standart':
      return 'No';
    case 'gift':
      return 'Gift Wrapping';
    default:
      log.error(`didn't recognize ${name}`);
      return 'No';
  }
};

export const createBookInOrderObject = (
  alias: TBookAlias,
  bookId: string,
  wrapping: TBookWrapping,
  cover: TBookCover,
): BookInOrder => ({
  alias,
  bookId,
  status: 0,
  wrapping,
  cover,
});

export const FEToBEProduct = ({
  alias,
  bookId,
  wrapping,
  cover,
}: Product): BookInOrder =>
  createBookInOrderObject(
    alias,
    bookId,
    FEToBEWrappingName(wrapping.name),
    cover.name,
  );

/** TODO: remove */
export const FEToBEShipping = ({
  id,
}: Pick<IShipping, 'id'>): TShippingMethod => id;

export const isBookStarryDreamData = (bookData: any): bookData is BookData =>
  bookData.alias === BOOK_ALIAS_STARRY_DREAM;

export const isBookWelcomeToTheWoldData = (
  bookData: any,
): bookData is BookWelcomeToTheWoldData =>
  bookData.alias === BOOK_ALIAS_WELCOME_TO_THE_WOLD;

export const isBookWhosBirthdayTomorrowData = (
  bookData: any,
): bookData is BookWhosBirthdayTomorrowData =>
  bookData.alias === BOOK_ALIAS_WHOS_BIRTHDAY_TOMORROW;

export const isBookWhereIsBirthdayCakeData = (
  bookData: any,
): bookData is BookWhereIsBirthdayCakeData =>
  bookData.alias === BOOK_ALIAS_WHERE_IS_BIRTHDAY_CAKE;

export const isBookStoryOfGrandpaGrandmaData = (
  bookData: any,
): bookData is BookStoryOfGrandpaGrandmaData =>
  bookData.alias === BOOK_ALIAS_STORY_OF_GRANDPA_GRANDMA;

export const getPlanetsText = (planets: TPlanet[]) =>
  planets.map((planet) => EN_SUMMARY_PLANET_TO_NAME_MAP[planet]).join('・');

const getBookDataSpecsLog = new Logger('getBookDataSpecs');

export const getBookDataSpecs = (
  bookData:
    | BookData
    | BookWelcomeToTheWoldData
    | BookWhosBirthdayTomorrowData
    | BookWhereIsBirthdayCakeData
    | BookStoryOfGrandpaGrandmaData,
  exceptFields: string[] = [],
): Specs => {
  const log = getBookDataSpecsLog;

  const { heroName, cover, messageText } = bookData;

  const specs: Specs = {
    /** hero's name */
    Name: heroName,
  };

  if (isBookStarryDreamData(bookData)) {
    const {
      birthDate,
      birthDateTimeZone,
      zodiacSigns,
      fileUploadStatus,
      planets,
      place,
      birthTime,
    } = bookData;

    Object.assign(specs, {
      /** birthday */
      'Date of birth': birthDateTimeZone
        ? formatInTimeZone(birthDate.toDate(), birthDateTimeZone, 'yyyy/MM/dd')
        : format(birthDate.toDate(), 'yyyy/MM/dd'),
      /** gender */
      Gender: enUS[`starry.dream.character.${bookData.character}`],
      /** horoscope */
      Zodiac: enUS[zodiacSigns],
      /** photo */
      Photo: fileUploadStatus > 1 ? 'Photo inserted' : 'No photo',
      /** planets to visit */
      Destination: planets && getPlanetsText(planets),
      /** birth location */
      'Place of birth': place?.formatted_address || 'No input',
      /** birth time */
      'Time of birth': birthTime || 'No input',
    });
  } else if (isBookWelcomeToTheWoldData(bookData)) {
    const {
      birthDate,
      birthDateTimeZone,
      hairStyle,
      parents,
      birthTime,
      timeOfTheYear,
    } = bookData;

    Object.assign(specs, {
      /** birthday */
      'Date of birth': birthDateTimeZone
        ? formatInTimeZone(birthDate.toDate(), birthDateTimeZone, 'yyyy/MM/dd')
        : format(birthDate.toDate(), 'yyyy/MM/dd'),
      /** parents combination */
      'Parent(s)': enUS[`wttw.${parents}`],
      /** skin style */
      Skin: bookData.frekles
        ? enUS[`wttw.frekles.${bookData.skinColor}`]
        : enUS[`wttw.nofrekles.${bookData.skinColor}`],
      /** hair style */
      Hair:
        hairStyle === 'straight'
          ? enUS[`wttw.straight.${bookData.hairColor}`]
          : enUS[`wttw.curl.${bookData.hairColor}`],
      /** clothes color */
      'Pajama color': capitalize(bookData.clothColor),
      /** birth time */
      'Time of birth': birthTime || 'No input',
      /** season */
      Season: enUS[timeOfTheYear],
      /** photo */
      Photo: 'Photo inserted',
    });
  } else if (isBookWhosBirthdayTomorrowData(bookData)) {
    const {
      birthDate,
      birthMonth,
      ageToBe,
      whosCelebrating,
      timeOfTheYear,
      fileUploadStatus,
    } = bookData;

    const _birthDate = new Date(
      new Date().getFullYear() - parseInt(ageToBe, 10),
      parseInt(birthMonth, 10) - 1,
      parseInt(birthDate, 10),
    );

    Object.assign(specs, {
      /** gender */
      Gender: enUS[`wbt.character.${bookData.character}`],
      /** birthday */
      // Birthday: `${{birthMonth} Month ${birthDate} Day}`,
      Birthday: `${format(_birthDate, 'MMMM do')}`,
      /** age to be */
      // ['Soon to be']: `${ageToBe} years`,
      'Soon to be': enUS[`wbt.years.${ageToBe}`],
      /** friends */
      Friends: capitalize(enUS[whosCelebrating]),
      /** season */
      Season: enUS[timeOfTheYear],
      /** photo */
      Photo: fileUploadStatus > 1 ? 'Photo inserted' : 'No photo',
    });
  } else if (isBookWhereIsBirthdayCakeData(bookData)) {
    const {
      birthDate,
      birthMonth,
      gender,
      age,
      hairStyle,
      glasses,
      timeOfTheYear,
      fileUploadStatus,
    } = bookData;

    Object.assign(specs, {
      /** character */
      Character: `${enUS[gender]} (${HAIR_STYLE_WIBC_TO_NAME_MAP[hairStyle]})`,
      /** glasses */
      Glasses: glasses ? 'With glasses' : 'No glasses',
      /** birthday */
      Birthday: `${birthMonth} Month ${birthDate} Day`,
      /** age to be */
      'Age to be': `${age} years`,
      /** season */
      Season: enUS[timeOfTheYear],
      /** photo */
      Photo: fileUploadStatus > 1 ? 'With photo' : 'No photo',
    });
  } else if (isBookStoryOfGrandpaGrandmaData(bookData)) {
    const {
      gender,
      skinColor,
      hairColor,
      hairStyle,
      beardStyle,
      glasses,
      hairColorInYoungerYears,
      grandchildren: {
        howMany,
        data: [child1, child2, child3],
      },
    } = bookData;

    delete specs.Name;

    Object.assign(specs, {
      [`${jpJP[gender]}の名前`]: heroName,
      Gender: jpJP[gender],
      'Skin color': jpJP[SKIN_COLOR_SOGG_TO_LOCALE_KEY_MAP[skinColor]],
      'Hair color': jpJP[HAIR_COLOR_SOGG_TO_LOCALE_KEY_MAP[hairColor]],
      'Hair style':
        // @ts-ignore
        GENDER_TO_HAIR_STYLE_SOGG_TO_LOCALE_KEY_MAP[gender][hairStyle],
      'Beard style': BEARD_STYLE_SOGG_TO_LOCALE_KEY_MAP[beardStyle],
      /** glasses */
      Glasses: glasses ? 'With glasses' : 'No glasses',
      'Hair color in younger years':
        HAIR_COLOR_SOGG_TO_LOCALE_KEY_MAP[hairColorInYoungerYears],
      Kids: `${howMany} ${howMany === 1 ? 'child' : 'children'}`,
      'First child name': child1?.childrenName,
      'Second child name': child2?.childrenName,
      'Third child name': child3?.childrenName,
      Photo: 'With photo',
    });
  } else {
    log.error("didn't recognize", { bookData });
  }

  Object.assign(specs, {
    /** dedication */
    Dedication: messageText,
  });

  if (isBookWelcomeToTheWoldData(bookData)) {
    Object.assign(specs, {
      /** cover design */
      'Cover design': enUS[`wttw.${bookData.coverImage}`],
    });
  }

  Object.assign(specs, {
    /** cover */
    'Cover type': cover ? `${capitalize(cover)} Cover` : '',
  });

  // removing excepted fields
  exceptFields.forEach((field) => delete specs[field]);

  // removing empty fields
  Object.keys(specs).forEach((key) => specs[key] || delete specs[key]);

  return specs;
};

const getProductNameLog = new Logger('getProductName');
export const getProductName = (
  alias: TBookAlias,
  heroName: string,
  gender?: TGender | TGenderGrandpaGrandma,
  childrenNames?: string[],
  zodiac?: string,
) => {
  const log = getProductNameLog;

  switch (alias) {
    case BOOK_ALIAS_STARRY_DREAM:
      return `${heroName} and ${zodiac}’s Adventure in the Stars`;
    case BOOK_ALIAS_WELCOME_TO_THE_WOLD:
      return `Welcome to the World, ${heroName}`;
    case BOOK_ALIAS_WHOS_BIRTHDAY_TOMORROW:
      return `Tomorrow is ${heroName}’s Birthday`;
    case BOOK_ALIAS_WHERE_IS_BIRTHDAY_CAKE:
      return `${heroName}、みつけて！たんじょうびまでのだいぼうけん`;
    case BOOK_ALIAS_STORY_OF_GRANDPA_GRANDMA:
      if (!childrenNames) {
        log.error(
          BOOK_ALIAS_STORY_OF_GRANDPA_GRANDMA,
          'should have childrenNames',
        );
        return '';
      }

      return `${childrenNames.join('と')}の${gender}、${heroName}`;
    default:
      log.error(`alias "${alias}" not handled yet`);
      return '';
  }
};

type TGetProductRequiredFields = 'alias' | 'bookId' | 'hero' | 'specs';

type TGetProductExcludedFields = 'wrappings' | 'name';

type TGetProductFields = Required<Pick<Product, TGetProductRequiredFields>> &
  Partial<Omit<Product, TGetProductRequiredFields | TGetProductExcludedFields>>;

export const getProduct = ({
  alias,
  bookId,
  covers,
  cover,
  hero,
  children,
  gender,
  image,
  wrapping,
  specs,
}: TGetProductFields): Product => {
  const book = getBookByAlias(alias);

  const p: Product = {
    alias,
    bookId,
    name: getProductName(alias, hero, gender, children, specs.Zodiac),
    children,
    gender,
    covers: covers || getCovers(alias),
    cover: cover || getCoverSoft(alias),
    hero,
    image: image || book.menuPhoto,
    wrappings,
    wrapping: wrapping || wrappingDefault,
    specs,
  };

  return p;
};

export const getCovers = memoizee(
  (alias: TBookAlias, prices?: TBookPrices): Cover[] => [
    getCoverSoft(alias, prices),
    getCoverHard(alias, prices),
  ],
);

export const getCover = (alias: TBookAlias, cover: TBookCover) => {
  if (cover === 'hard') {
    return getCoverHard(alias);
  }

  return getCoverSoft(alias);
};

export const getCoverSoft = memoizee(
  (
    alias: TBookAlias,
    prices: TBookPrices = getBookByAlias(alias).prices,
  ): Cover => {
    const price = prices.find((_price) => _price[0] === 'soft')?.[1];

    if (!price) throw getError('soft price not found in', { prices });

    return {
      name: 'soft',
      summary:
        'A charming, child-friendly softcover crafted from strong, durable paper.',
      price,
      image: 'assets/images/soft.png',
    };
  },
);

export const getCoverHard = memoizee(
  (
    alias: TBookAlias,
    prices: TBookPrices = getBookByAlias(alias).prices,
  ): Cover => {
    const price = prices.find((_price) => _price[0] === 'hard')?.[1];

    if (!price) throw getError('hard price not found in', { prices });

    return {
      name: 'hard',
      summary:
        'A durable hardcover with a high-quality matte finish. Perfect as a gift and made to last.',
      price,
      image: 'assets/images/hard.png',
    };
  },
);

export const getCalculatorItems = (
  products: Product[],
  discountCampaign?: IDiscountCampaign,
  couponData?: TCouponData,
  shipping?: IShipping,
): ICalculatorComponentItem[] => {
  const calculatorItems: ICalculatorComponentItem[] = [];

  calculatorItems.push(
    ...products.map((p) => {
      let price = p.cover.price.value + p.wrapping.price.value;

      const discount =
        discountCampaign && getBooksDiscount(discountCampaign, p.alias);

      if (discount) {
        price = calculateDiscount(price, discount);
      }

      if (checkStripeCoupon(couponData)) {
        price = calculateDiscount(price, getCouponDiscount(couponData));
      }

      return {
        name: 'Product fee',
        price,
        currency: p.cover.price.currency,
      };
    }),
  );

  if (shipping) {
    const shippingMethod = shipping.id;
    let shippingPrice = shipping.price;

    const shippingDiscount =
      discountCampaign && getShippingDiscount(discountCampaign, shippingMethod);

    if (shippingDiscount) {
      shippingPrice = calculateDiscount(shippingPrice, shippingDiscount);
    }

    calculatorItems.push({
      name: 'Shipping fee',
      price: shippingPrice,
      currency: shipping.currency,
    });
  }

  return calculatorItems;
};

export const getProductPrice = (p: Product): IBookPrice => ({
  value: p.cover.price.value + p.wrapping.price.value,
  currency: p.cover.price.currency,
});

// ! note: do not use margin in el style attr
export const controlPopupPosition = (el: HTMLElement, offset: number): void => {
  if (!el) {
    console.error('controlPopupPosition: element is not provided', el);
    return;
  }

  const rect: DOMRect = el.getBoundingClientRect();
  const marginLeft =
    (el.style.marginLeft ? parseInt(el.style.marginLeft, 10) : 0) * -1;
  const rectRight = rect.x + rect.width + offset + marginLeft;

  // check if element crossed the edge
  if (rectRight > window.innerWidth) {
    const gap = rectRight - window.innerWidth;

    el.style.marginLeft = `-${gap}px`;
  } else {
    el.style.marginLeft = '';
  }
};

export const checkStripeCoupon = (coupon: any): coupon is StripeBE.Coupon =>
  coupon && ('percent_off' in coupon || 'amount_off' in coupon);

export const getCouponDiscount = ({
  percent_off,
  amount_off,
}: StripeBE.Coupon): string => {
  if (percent_off) {
    return `${percent_off}%`;
  }
  if (amount_off) {
    return `${amount_off}`;
  }

  return '0';
};

export const checkControlShowError = (
  control: AbstractControl | undefined,
): boolean => {
  if (!control) return false;

  const { touched, dirty, invalid } = control;

  return touched && dirty && invalid;
};

export const checkAllControlsTouchedInFormGroup = (fg: FormGroup): boolean => {
  const { controls } = fg;

  return Object.values(controls).every((c) => c.touched);
};

// export const formDataCustomerInfoToAddressBilingData = (
//   fCI: IFormCustomerInfoData,
// ): IAddressBillingData => ({
//   familyName: fCI.lastName,
//   givenName: fCI.firstName,
//   postalCode: `${fCI.postalCode}`,
//   addressLine1: fCI.state ? getRegionByCode(fCI.state).name : '',
//   addressLine2: fCI.addressSecond,
//   addressLine3: fCI.addressThird,
//   phone: `${fCI.phone}`,
//   emailAddress: fCI.email,
// });

// export const addressBillingDataToFormDataCustomerInfo = (
//   aBD: IAddressBillingData,
// ): IFormCustomerInfoData => {
//   const {
//     familyName: lastName,
//     givenName: firstName,
//     postalCode,
//     addressLine1,
//     addressLine2: addressSecond,
//     addressLine3: addressThird,
//     phone,
//     emailAddress: email,
//   } = aBD;

//   const [postalCodeFirst, postalCodeSecond] = postalCode.split('-');
//   const addressFirst = Regions.findIndex(r => r.name === addressLine1).toString();
//   const [phoneFirst, phoneSecond, phoneThird] = phone.split('-');

//   return {
//     lastName,
//     firstName,
//     postalCodeFirst,
//     postalCodeSecond,
//     addressFirst,
//     addressSecond,
//     addressThird,
//     phoneFirst,
//     phoneSecond,
//     phoneThird,
//     email,
//   }
// };

// export const formDataShippingAddressToAddressShippingData = (
//   fSA: IFormDataShippingAddress,
// ): IAddressShippingData => ({
//   familyName: fSA.lastName,
//   givenName: fSA.firstName,
//   postalCode: fSA.postalCode,
//   addressLine1: fSA.state,
//   addressLine2: fSA.addressSecond,
//   addressLine3: fSA.addressThird,
//   phone: fSA.phone,
// });

export const scrollToElement = (
  element: Element,
  container = document.documentElement,
): void => {
  const { x, y } = element.getBoundingClientRect();
  let yGap = 0;

  if (container === document.documentElement) {
    const headerGapElement = document.getElementsByTagName(
      'app-section-header-gap',
    )[0];

    if (headerGapElement) {
      yGap = headerGapElement.getBoundingClientRect().height;
    }
  }

  container.scrollBy({
    top: y - yGap,
    left: x,
    behavior: 'smooth',
  });
};

const scrollToSelectorLog = new Logger('scrollToSelector');
export const scrollToSelector = (
  selector: string,
  container = document.documentElement,
): void => {
  const log = scrollToSelectorLog;
  const element = document.querySelector(selector);

  if (!element) {
    log.error('element not found by selector', selector);
    return;
  }

  scrollToElement(element, container);
};

export const getPaymentInformation = (
  books: {
    alias: TBookAlias;
    cover: TBookCover;
  }[],
  shippingMethod: TShippingMethod,
  shippingPrice: number,
  type: IPaymentInformation['type'],
  couponData?: TCouponData,
  discountCampaign?: IDiscountCampaign,
): IPaymentInformation => {
  const shippingDiscount =
    discountCampaign && getShippingDiscount(discountCampaign, shippingMethod);

  shippingPrice = shippingDiscount
    ? calculateDiscount(shippingPrice, shippingDiscount)
    : shippingPrice;

  /** raw books price */
  const productSubtotal: IPaymentInformation['productSubtotal'] = books.reduce(
    (price, { alias, cover }) => {
      const bookPrice = getCover(alias, cover).price.value;

      // calculate discount
      let bookPriceWithDiscount = bookPrice;

      const discount =
        discountCampaign && getBooksDiscount(discountCampaign, alias);

      if (discount) {
        bookPriceWithDiscount = calculateDiscount(
          bookPriceWithDiscount,
          discount,
        );
      }

      price += bookPriceWithDiscount;

      return price;
    },
    0,
  );

  /** final books price */
  let productTotal = productSubtotal;
  let couponCode: IPaymentInformation['couponCode'];

  // apply stripe coupon
  if (checkStripeCoupon(couponData)) {
    productTotal = calculateDiscount(
      productTotal,
      getCouponDiscount(couponData),
    );
    couponCode = couponData.id;
  }

  return {
    couponCode,
    discountAmount: productSubtotal - productTotal,
    productSubtotal,
    productTotal,
    shippingPrice,
    orderTotal: productTotal + shippingPrice,
    type,
    taxPrice: 0,
    paymentMethod: 'Card',
    paymentIntent: 'undefined',
  };
};

const onbeforeunloadLog = new Logger('OnBeforeUnload');
export const setOnBeforeUnload = (msg: string) => {
  if (!environment.production) {
    onbeforeunloadLog.debug('skipped non-production env');
    return;
  }

  window.onbeforeunload = (e: BeforeUnloadEvent) => {
    if (e === undefined) {
      e = window.event;
    }

    if (e) {
      e.returnValue = msg;
    }

    return msg;
  };
};

export const resetOnBeforeUnload = () => {
  window.onbeforeunload = null;
};
